<template>
  <div class="productInformationDetalis">
    <div class="banner" :style="{background:'url(' + baseHostUrl + productsdata.imgurl + ')',backgroundSize: '100% 100%', backgroundPosition: '50% 50%'}">
      <div class="content">
        <div class="box">
          <div class="title" data-aos="fade-up">{{productsdata.title}}</div>
          <div class="desc_details" data-aos="fade-up">{{productsdata.desc_details}}</div>
        </div>
      </div>
    </div>
    <div class="bannerMB" :style="{background:'url(' + baseHostUrl + productsdata.imgurl + ')',backgroundSize: '100% 100%', backgroundPosition: '50% 50%'}"></div>
    <div class="contentMB">
        <div class="box">
          <div class="title" data-aos="fade-up">{{productsdata.title}}</div>
          <div class="desc_details" data-aos="fade-up">{{productsdata.desc_details}}</div>
        </div>
      </div>
    <div class="guigeBoxAll">
      <div class="guigeBox">
        <div class="content">
          <div class="title">硬件规格</div>
          <div class="BoxitemBoxBox">
            <div class="itemBox">
              <div class="item">
                <div class="lable">网络接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.network" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">PCIe 接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.pcie" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">管理控制接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.management" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">CPU</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.cpu" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">内存</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.memory" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">尺寸</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.size" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">功耗</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.powerwaste" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
            <div class="itemBox">
              <div class="item">
                <div class="lable">存储卸载功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.storage" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">运维管控功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.operation" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guigeBox texing">
        <div class="content">
          <div class="title">功能特性</div>
          <div class="BoxitemBoxBox">
            <div class="itemBox">
              <div class="item">
                <div class="lable">基础功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.basic" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">RDMA 功能（在研）</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.rdma" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
            <div class="itemBox">
              <div class="item">
                <div class="lable">网络及虚拟化卸载功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.virtual" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="guigeBoxMB">
      <div class="guigeBox">
        <div class="content">
          <div class="title">硬件规格</div>
          <div class="BoxitemBoxBox">
            <div class="itemBox">
              <div class="item">
                <div class="lable">网络接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.network" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">PCIe 接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.pcie" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">管理控制接口</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.management" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">CPU</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.cpu" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">尺寸</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.size" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">功耗</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.powerwaste" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
            <div class="itemBox">
              <div class="item">
                <div class="lable">存储卸载功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.storage" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">运维管控功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.operation" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guigeBox texing">
        <div class="content">
          <div class="title">功能特性</div>
          <div class="BoxitemBoxBox">
            <div class="itemBox">
              <div class="item">
                <div class="lable">基础功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.basic" :key="index">{{item}}</div>
                </div>
              </div>
              <div class="item">
                <div class="lable">RDMA 功能<br>（在研）</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.rdma" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
            <div class="itemBox">
              <div class="item">
                <div class="lable">网络及虚拟化<br>卸载功能</div>
                <div class="text">
                  <div class="t" v-for="(item,index) in productsdata.virtual" :key="index">{{item}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {baseHostUrl,getproductsdataPost} from '@/utils/index.js'
export default {
  name: 'productInformationDetalis',
  components: {

  },
  data () {
    return {
      pcOrmb: true,
      page: 1,
      productsdata: [],
      baseHostUrl: '',
      count: 0,
      noData: false
    }
  },
  computed: {
  },
  created() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {  
      this.pcOrmb = false
    }else{  
      this.pcOrmb = true
    } 
  },
  mounted() {
    this.getproductsdataPost()
    this.baseHostUrl = baseHostUrl()
  },
  methods: {

    async getproductsdataPost() {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        product_id: this.$route.query.id
      }
      
      let res = await getproductsdataPost(data) 
      this.productsdata = res.data
      // this.count = res.data.count
      // if (res.data.list.length < 10){
      //   this.noData = true
      // }
    },
  }
}
</script>

<style lang="scss" scoped>

.dynamic{
  background: #F6F7F9;
}
.banner{
  height: 600px;
  display: flex;
  align-items: center;
  background-size:  cover !important;
  background-position: 50% 50% !important;
  .content{
    width: 63vw;
    margin: auto;
    .box{
      width:30vw;
      .title{
        font-size: 1.8vw;
        font-weight: bold;
        color: #0B71B7;
      }
      .desc_details{
        font-size: 0.85vw;
        line-height: 1.5vw;
        margin-top: 1.2vw;
        color: #666666;
      }
    }
    
  }
}
.guigeBox{
  background: #fff;
  .content{
    width: 63vw;
    margin: auto;
    padding: 3vw 0 2vw 0;
    .title{
      font-size: 1.8vw;
      font-weight: bold;
      color: #0B71B7;
      border-bottom: solid 1px #EEEEEE;
      padding-bottom: 1.8vw;
    }
    .BoxitemBoxBox{
      display: flex;
      padding-top: 1.6vw;
      justify-content: space-between;
    }
    .itemBox{
      flex: 0 0 45%;
      
      .item{
        display: flex;
        margin-bottom: 1.2vw;
        .lable{
          font-size: 0.85vw;
          font-weight: bold;
          flex: 1;
        }
        .text{
          color: #666666;
          font-size: 0.85vw;;
          flex: 1;
          
          line-height: 1.5vw;
        }
      }
    }
  }
}
.texing{
  background: #F2F4F7;
  .title{
    border-bottom: solid 1px #E0E0E0 !important;
  }
}
.guigeBoxMB{
  display: none;
}
.bannerMB{
  display: none;
}
.contentMB{
  display: none;
}
@media screen and (max-width:750px){
  .bannerMB{
    display: block;
  }
  .banner{
    display: none;
  }
  .bannerMB{
    height: 60vw;
    background-size:  cover !important;
    background-position: 50% 50% !important;
  }
  .contentMB{
    display: block;
    margin: 0 15px;
    .box{
      width: 100%;
      .title{
        font-size: 6vw;
        font-weight: bold;
        color: #0B71B7;
        text-align: center;
        margin-top: 6vw;
      }
      .desc_details{
        font-size: 4vw;
        line-height: 8vw;
        margin-top: 10px;
        color: #666666;
        text-align: justify;
      }
    }
  }
  .guigeBoxAll{
    display: none;
  }
  .guigeBoxMB{
    display: block;
    .content{
      width: auto;
      padding: 20px 15px;
      .title{
        font-size: 6vw;
        padding-bottom: 20px;
        text-align: center;
        margin-top: 20px;
      }
      .BoxitemBoxBox{
        display: block;
        .itemBox{
          .item{
            .lable{
              font-size: 4vw;
              flex: 0 0 120px;
            }
            .text{
              margin-left: 15px;
              font-size: 4vw;
              text-align: right;
              line-height: 10vw;
            }
          }
        }
      }
    }
  }
}
</style>